<template>
    <div class="app-content app-container">
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                label-width="100px"
                size="medium"
                @submit.native.prevent
            >
                <el-form-item label="名称" prop="name">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.name"
                        placeholder="请输入"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
            <div>
                <el-button type="primary" plain @click="addReport"
                    >新增</el-button
                >
                <!-- <el-button type="danger" plain @click="allDeleteClick"
                    >删除</el-button
                > -->
            </div>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>

                <el-table-column prop="id" label="#" width="55">
                </el-table-column>
                <el-table-column prop="name" label="名称"> </el-table-column>
                <el-table-column prop="type" label="分组类型" width="100">
                    <template slot-scope="scope">{{
                        scope.row.type | groupType
                    }}</template>
                </el-table-column>
                <el-table-column width="200" prop="status" label="企业组标签">
                    <template slot-scope="scope">
                        <el-tag
                            :type="scope.row.groupLabel | companyTypeTag"
                            effect="plain"
                        >
                            {{ scope.row.groupLabel | companyType }}
                        </el-tag>
                    </template>
                    <!-- <template slot-scope="scope">{{
                        scope.row.groupLabel | companyType
                    }}</template> -->
                </el-table-column>
                <el-table-column
                    prop="groupContainCompany"
                    width="100"
                    label="企业数量"
                >
                </el-table-column>
                <el-table-column width="100" prop="status" label="状态">
                    <template slot-scope="scope">{{
                        scope.row.status | effective
                    }}</template>
                </el-table-column>

                <el-table-column prop="index" label="显示顺序" width="100">
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="350">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editClick(scope.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            v-if="scope.row.status !== -1"
                            @click="exportClick(scope.row)"
                            >导入企业</el-button
                        >
                        <el-button
                            type="text"
                            :disabled="scope.row.groupLabel === 'YB'"
                            v-if="scope.row.status !== -1"
                            @click="setGroupClick(scope.row.id, 'YB')"
                            >设为样本企业组</el-button
                        >
                        <el-button
                            type="text"
                            v-if="scope.row.status !== -1"
                            :disabled="scope.row.groupLabel === 'YB'"
                            @click="setGroupClick(scope.row.id, scope.row.groupLabel === 'ZB'?'FYB':'ZB')"
                            >{{scope.row.groupLabel === 'ZB'?'取消直报企业组':'设为直报企业组'}}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
        <!-- 弹窗 信息编辑-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
            <div>
                <el-form
                    label-width="100px"
                    :model="formLabelAlign"
                    ref="ruleForm"
                    :rules="rules"
                >
                    <!-- <el-row :gutter="24">
                        <el-col :span="15"> -->
                    <el-form-item label="企业组名称" prop="name">
                        <el-input v-model="formLabelAlign.name"></el-input>
                    </el-form-item>
                    <!-- </el-col>
                        <el-col :span="15"> -->
                    <el-form-item label="类型" prop="type">
                        <el-select
                            style="width: 100%"
                            v-model="formLabelAlign.type"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in typeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- </el-col>
                        <el-col :span="15"> -->
                    <el-form-item label="排序" prop="index">
                        <el-input v-model="formLabelAlign.index"></el-input>
                    </el-form-item>
                    <!-- </el-col>
                        <el-col :span="15"> -->
                    <el-form-item label="状态" prop="status">
                        <el-select
                            style="width: 100%"
                            v-model="formLabelAlign.status"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in statusList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- </el-col>
                    </el-row> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editSubmitClick('ruleForm')"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 导入弹窗 -->
        <el-dialog
            title="导入企业"
            :visible.sync="dialogExportVisible"
            width="30%"
        >
            <div>
                <div class="down-box">
                    <p>请先下载企业组导入模板</p>
                    <a
                        href="/download.xlsx"
                        class="down"
                        download="下载模板.xlsx"
                        >下载模板.xlsx</a
                    >
                </div>

                <el-upload
                    :action="actions"
                    :before-upload="handleBeforeUpload"
                    :file-list="fileList"
                    :limit="1"
                    :data="date"
                    :on-error="handleUploadError"
                    :on-exceed="handleExceed"
                    :on-success="handleUploadSuccess"
                    :show-file-list="false"
                    :headers="headers"
                    class="upload-file-uploader"
                    ref="fileUpload"
                >
                    <!-- 上传按钮 -->
                    <el-button size="mini" type="primary">选取文件</el-button>
                    <!-- 上传提示 -->
                    <div class="el-upload__tip" slot="tip">
                        请上传
                        <template v-if="fileSize">
                            大小不超过
                            <b style="color: #f56c6c">{{ fileSize }}MB</b>
                        </template>
                        <template v-if="fileType">
                            格式为
                            <b style="color: #f56c6c">{{
                                fileType.join('/')
                            }}</b>
                        </template>
                        的文件
                    </div>
                </el-upload>
                <!-- 文件列表 -->
                <transition-group
                    class="upload-file-list el-upload-list el-upload-list--text"
                    name="el-fade-in-linear"
                    tag="ul"
                >
                    <li
                        :key="file.url"
                        class="el-upload-list__item ele-upload-list__item-content"
                        v-for="(file, index) in fileList"
                    >
                        <el-link
                            :href="file.url"
                            :underline="false"
                            target="_blank"
                            class="upload-image"
                        >
                            <span class="el-icon-document">
                                {{ file.name }}
                            </span>
                        </el-link>
                        <div class="ele-upload-list__item-content-action">
                            <el-link
                                :underline="false"
                                @click="handleDelete(index)"
                                type="danger"
                                >删除</el-link
                            >
                        </div>
                    </li>
                </transition-group>
                <el-alert
                    style="margin-top: 10px"
                    v-if="message"
                    :title="message"
                    type="error"
                    show-icon
                    :closable="false"
                >
                </el-alert>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogExportVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="exportSubmitClick"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { getToken } from '@/utils/auth'
// import fileUpload from '@/components/FileUpload'
// import FileUploadReport from '@/components/FileUploadReport'
import {
    getCompanyGroupListApi,
    getCompanyGroupCreateApi,
    getCompanyGroupUpdateApi,
    getCompanyGroupDeleteApi,
    getCompanyGroupExportApi,
    setGroupTagApi,
} from '@/api/company'
export default {
    // 注册组件
    components: {
        Pagination,
        // FileUploadReport,
    },
    data() {
        return {
            message: '',
            actions: process.env.VUE_APP_BASE_API + '/upload/path',
            headers: {
                Authorization: getToken(),
            },
            date: {
                dir: 'excel',
            },
            title: '',
            dialogExportVisible: false,
            fileType: ['xls', 'xlsx'],
            fileSize: 20,
            dialogVisible: false,
            typeList: [
                {
                    label: '外贸',
                    value: 'WM',
                },
                {
                    label: '外资',
                    value: 'WZ',
                },
                {
                    label: '外经',
                    value: 'WJ',
                },
            ],
            statusList: [
                {
                    label: '有效',
                    value: 0,
                },
                {
                    label: '无效',
                    value: -1,
                },
            ],
            formInline: {},
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
            formLabelAlign: {},
            fileList: [],
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入企业组名称',
                        trigger: 'blur',
                    },
                ],
                type: [
                    { required: true, message: '请选择类型', trigger: 'blur' },
                ],
                status: [
                    { required: true, message: '请选择状态', trigger: 'blur' },
                ],
            },
        }
    },
    created() {
        if (sessionStorage.getItem('formInline')) {
            this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
        }
        this.getCompanyGroupListFun()
    },
    methods: {
        async getCompanyGroupListFun(currentPage, pageSize) {
            var pageparm = localStorage.getItem('pageparm')?JSON.parse(localStorage.getItem('pageparm')):null
            console.log('pageparm', pageparm)
            var start = currentPage ? currentPage * pageSize - pageSize : pageparm?pageparm.currentPage * pageparm.pageSize - pageparm.pageSize:0
            var params = {
                start: start,
                length: pageSize ? pageSize : 10,
                draw: 1,
                name: '',
                orderStr: 'time desc',
            }
            var p = Object.assign(params, this.formInline)
            var res = await getCompanyGroupListApi(p)
            // console.log('企业组', res)
            this.pageparm.total = res.recordsTotal
            this.tableData = res.data
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            this.getCompanyGroupListFun()
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline = {
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.getCompanyGroupListFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            this.getCompanyGroupListFun(parm.currentPage, parm.pageSize)
            localStorage.setItem('pageparm', JSON.stringify(parm))
        },
        coursewareClick(value) {
            console.log('返回数据', value)
        },
        // 删除
        allDeleteClick() {
            var ids = this.multipleSelection.map((item) => item.id)
            this.$alert(
                '这确定要删除共' +
                    this.multipleSelection.length +
                    '条数据吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await getCompanyGroupDeleteApi(params)
                            console.log('删除结果', res)
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                this.getCompanyGroupListFun()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                        }
                    },
                }
            )
        },
        // 设置样本企业组与设置直报企业组
        async setGroupClick(id, value) {
            if (value === 'YB') {
                this.$alert(
                    '“样本企业组只能设置一个，您确定要将当前企业组设置为样本组替换掉之前的设置吗？',
                    {
                        confirmButtonText: '确定',
                        callback: async (action) => {
                            if (action === 'confirm') {
                                var parmas = {
                                    id,
                                    groupLabel: value,
                                }
                                var res = await setGroupTagApi(parmas)
                                if (res.code === 200 && res.data != '500') {
                                    this.$message({
                                        type: 'success',
                                        message: '设置成功',
                                    })
                                    this.getCompanyGroupListFun()
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: '设置失败',
                                    })
                                }
                            }
                        },
                    }
                )
            } else {
                var parmas = {
                    id,
                    groupLabel: value,
                }
                var res = await setGroupTagApi(parmas)
                if (res.code === 200 && res.data != '500') {
                    this.$message({
                        type: 'success',
                        message: '设置成功',
                    })
                    this.getCompanyGroupListFun()
                } else {
                    this.$message({
                        type: 'error',
                        message: '设置失败',
                    })
                }
            }
        },
        // 导入
        exportClick(row) {
            this.id = row.id
            this.fileList = []
            this.message = ''
            this.excelPath = ''
            this.dialogExportVisible = true
        },
        // 上传前校检格式和大小
        handleBeforeUpload(file) {
            // 校检文件类型
            if (this.fileType) {
                const fileName = file.name.split('.')
                const fileExt = fileName[fileName.length - 1]
                const isTypeOk = this.fileType.indexOf(fileExt) >= 0
                if (!isTypeOk) {
                    this.$modal.msgError(
                        `文件格式不正确, 请上传${this.fileType.join(
                            '/'
                        )}格式文件!`
                    )
                    return false
                }
            }
            // 校检文件大小
            if (this.fileSize) {
                const isLt = file.size / 1024 / 1024 < this.fileSize
                if (!isLt) {
                    this.$modal.msgError(
                        `上传文件大小不能超过 ${this.fileSize} MB!`
                    )
                    return false
                }
            }
            this.$modal.loading('正在上传文件，请稍候...')
            this.number++
            return true
        },
        // 文件个数超出
        handleExceed() {
            this.$modal.msgError(`上传文件数量不能超过1个!`)
        },
        // 上传失败
        handleUploadError() {
            this.$modal.msgError('上传文件失败，请重试.')
            this.$modal.closeLoading()
        },
        // 上传成功回调
        handleUploadSuccess(res, file) {
            this.$modal.closeLoading()
            if (res.code === 200) {
                this.excelPath = res.url
                this.fileList.push(res)
            } else {
                this.number--
                this.$modal.closeLoading()
                this.$modal.msgError(res.msg)
                this.$refs.fileUpload.handleRemove(file)
            }
        },
        // 删除文件
        handleDelete(index) {
            this.fileList.splice(index, 1)
            // this.$emit('input', this.listToString(this.fileList))
        },
        async upload() {
            console.log('excelPath', this.excelPath)
            var params = {
                excelPath: this.excelPath,
            }
            var res = await getCompanyGroupExportApi(this.id, params)
            if (res.code === 200) {
                this.dialogExportVisible = false
                this.$message({
                    message: res.msg,
                    type: 'success',
                })
                this.getCompanyGroupListFun()
            } else {
                this.$message({
                    message: res.msg,
                    type: 'error',
                })
            }
        },
        // 提交导入
        exportSubmitClick() {
            if (this.excelPath) {
                this.upload()
            } else {
                this.message = '请上传导入企业文件'
            }
        },
        // 新增
        addReport() {
            this.title = '新增企业组'
            this.dialogVisible = true
        },
        // 编辑模板
        editClick(row) {
            this.title = '编辑企业组信息'
            this.formLabelAlign = {}
            this.formLabelAlign = row
            this.dialogVisible = true
        },
        // 编辑提交
        editSubmitClick(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.editSubmit()
                } else {
                    this.$message({
                        type: 'error',
                        message: '请检查是否有提示选项'
                    })
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 提交数据
        async editSubmit() {
            if (this.formLabelAlign.id) {
                this.getCompanyGroupUpdateFun()
            } else {
                this.getCompanyGroupCreateFun()
            }
        },
        // 更新企业组
        async getCompanyGroupUpdateFun() {
            delete this.formLabelAlign.time
            delete this.formLabelAlign.timeUpdate
            var res = await getCompanyGroupUpdateApi(this.formLabelAlign)
            if (res.code === 200) {
                this.$message({
                    message: '更新成功',
                    type: 'success',
                })
                this.dialogVisible = false
                this.getCompanyGroupListFun()
            } else {
                this.$message({
                    message: '更新失败',
                    type: 'error',
                })
            }
        },
        // 新增企业组
        async getCompanyGroupCreateFun() {
            var res = await getCompanyGroupCreateApi(this.formLabelAlign)
            if (res.code === 200) {
                this.$message({
                    message: '更新成功',
                    type: 'success',
                })
                this.dialogVisible = false
                this.getCompanyGroupListFun()
            } else {
                this.$message({
                    message: '更新失败',
                    type: 'error',
                })
            }
        },
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
.down-box {
    margin-bottom: 20px;
}
.down {
    font-size: 14px;
    /* color: #333; */
    text-decoration: none;
}
</style>
